<template>
  <div>
    <div class="header-print">
      <invoice-pdf :inner-data="innerData" :inner-template="dataHeader" v-if="printType == 'pdf'" :show-q-r-code="showQRCode" :title-type="titleType"></invoice-pdf>
      <template v-else>
        <template v-if="dataHeader && dataHeader.type == 'SalesInvoice'">
          <sales-invoice1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></sales-invoice1>
          <sales-invoice2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></sales-invoice2>
          <sales-invoice3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></sales-invoice3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'SalesInvoiceRefund'">
          <sales-refund1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></sales-refund1>
          <sales-refund2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></sales-refund2>
          <sales-refund3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></sales-refund3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'SalesCredit'">
          <sales-credit1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></sales-credit1>
          <sales-credit2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></sales-credit2>
          <sales-credit3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></sales-credit3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'SalesDebit'">
          <sales-debit1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></sales-debit1>
          <sales-debit2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></sales-debit2>
          <sales-debit3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></sales-debit3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'SalesQuotation'">
          <sales-quotations1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></sales-quotations1>
          <sales-quotations2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></sales-quotations2>
          <sales-quotations3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></sales-quotations3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSalesQuotation'">
          <general-sales-quotations1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-quotations1>
          <general-sales-quotations2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-quotations2>
          <general-sales-quotations3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-quotations3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <!--------------------->
        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSalesCredit'">
          <general-sales-credit1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-credit1>
          <general-sales-credit2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-credit2>
          <general-sales-credit3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-credit3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSalesDebit'">
          <general-sales-debit1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-debit1>
          <general-sales-debit2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-debit2>
          <general-sales-debit3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-debit3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSales'">
          <general-sales-invoices1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-invoices1>
          <general-sales-invoices2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-invoices2>
          <general-sales-invoices3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-invoices3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSalesInvoiceRefund'">
          <general-sales-refund1 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-refund1>
          <general-sales-refund2 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-refund2>
          <general-sales-refund3 :inner-data="innerData" :inner-template="dataHeader" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-refund3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'GeneralSalesRent'">
          <general-sales-rent1 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 1"></general-sales-rent1>
          <general-sales-rent2 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 2"></general-sales-rent2>
          <general-sales-rent3 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 3"></general-sales-rent3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>

        <template v-else-if="dataHeader && dataHeader.type == 'SalesRent'">
          <sales-rent1 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 1"></sales-rent1>
          <sales-rent2 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 2"></sales-rent2>
          <sales-rent3 :inner-data="innerData" v-if="dataHeader && dataHeader.header_temp == 3"></sales-rent3>
          <div v-else-if="dataHeader && dataHeader.header_height" :style="{'height':dataHeader.header_height+'cm'}"></div>
        </template>
      </template>
    </div>

  </div>
</template>

<script>
import SalesInvoice1 from "@/view/content/printing-heads/sales-invoices/SalesInvoice1.vue";
import InvoicePdf from "@/view/content/printing-heads/InvoicePdf.vue";
import SalesRefund1 from "@/view/content/printing-heads/sales-refund/SalesRefund1.vue";
import SalesCredit1 from "@/view/content/printing-heads/sales-credit/SalesCredit1.vue";
import SalesDebit1 from "@/view/content/printing-heads/sales-debit/SalesDebit1.vue";
import SalesQuotations1 from "@/view/content/printing-heads/sales-quotations/SalesQuotations1.vue";

import GeneralSalesCredit1 from "@/view/content/printing-heads/general-sales-credit/GeneralSalesCredit1.vue";
import GeneralSalesDebit1 from "@/view/content/printing-heads/general-sales-debit/GeneralSalesDebit1.vue";
import GeneralSalesInvoices1 from "@/view/content/printing-heads/general-sales-invoices/GeneralSalesInvoices1.vue";
import GeneralSalesRefund1 from "@/view/content/printing-heads/general-sales-refund/GeneralSalesRefund1.vue";
import GeneralSalesRent1 from "@/view/content/printing-heads/general-sales-rent/GeneralSalesRent1.vue";
import SalesRent1 from "@/view/content/printing-heads/sales-rent/SalesRent1.vue";
import SalesInvoice2 from "@/view/content/printing-heads/sales-invoices/SalesInvoice2.vue";
import GeneralSalesInvoices2 from "@/view/content/printing-heads/general-sales-invoices/GeneralSalesInvoices2.vue";
import SalesInvoice3 from "@/view/content/printing-heads/sales-invoices/SalesInvoice3.vue";
import GeneralSalesInvoices3 from "@/view/content/printing-heads/general-sales-invoices/GeneralSalesInvoices3.vue";
import SalesRent2 from "@/view/content/printing-heads/sales-rent/SalesRent2.vue";
import SalesRent3 from "@/view/content/printing-heads/sales-rent/SalesRent3.vue";
import GeneralSalesRent2 from "@/view/content/printing-heads/general-sales-rent/GeneralSalesRent2.vue";
import GeneralSalesRent3 from "@/view/content/printing-heads/general-sales-rent/GeneralSalesRent3.vue";
import GeneralSalesCredit2 from "@/view/content/printing-heads/general-sales-credit/GeneralSalesCredit2.vue";
import GeneralSalesCredit3 from "@/view/content/printing-heads/general-sales-credit/GeneralSalesCredit3.vue";
import SalesCredit2 from "@/view/content/printing-heads/sales-credit/SalesCredit2.vue";
import SalesCredit3 from "@/view/content/printing-heads/sales-credit/SalesCredit3.vue";
import SalesDebit2 from "@/view/content/printing-heads/sales-debit/SalesDebit2.vue";
import SalesDebit3 from "@/view/content/printing-heads/sales-debit/SalesDebit3.vue";
import GeneralSalesDebit2 from "@/view/content/printing-heads/general-sales-debit/GeneralSalesDebit2.vue";
import GeneralSalesDebit3 from "@/view/content/printing-heads/general-sales-debit/GeneralSalesDebit3.vue";
import GeneralSalesRefund2 from "@/view/content/printing-heads/general-sales-refund/GeneralSalesRefund2.vue";
import GeneralSalesRefund3 from "@/view/content/printing-heads/general-sales-refund/GeneralSalesRefund3.vue";
import SalesRefund3 from "@/view/content/printing-heads/sales-refund/SalesRefund3.vue";
import SalesRefund2 from "@/view/content/printing-heads/sales-refund/SalesRefund2.vue";
import GeneralSalesQuotations1 from "@/view/content/printing-heads/general-sales-quotations/GeneralSalesQuotations1.vue";
import GeneralSalesQuotations2 from "@/view/content/printing-heads/general-sales-quotations/GeneralSalesQuotations2.vue";
import GeneralSalesQuotations3 from "@/view/content/printing-heads/general-sales-quotations/GeneralSalesQuotations3.vue";
import SalesQuotations2 from "@/view/content/printing-heads/sales-quotations/SalesQuotations2.vue";
import SalesQuotations3 from "@/view/content/printing-heads/sales-quotations/SalesQuotations3.vue";

export default {
  name: "MainHeader",
  components: {
    SalesQuotations3,
    SalesQuotations2,
    GeneralSalesQuotations3,
    GeneralSalesQuotations2,
    GeneralSalesQuotations1,
    SalesRefund2,
    SalesRefund3,
    GeneralSalesRefund3,
    GeneralSalesRefund2,
    GeneralSalesDebit3,
    GeneralSalesDebit2,
    SalesDebit3,
    SalesDebit2,
    SalesCredit3,
    SalesCredit2,
    GeneralSalesCredit3,
    GeneralSalesCredit2,
    GeneralSalesRent3,
    GeneralSalesRent2,
    SalesRent3,
    SalesRent2,
    GeneralSalesInvoices3,
    SalesInvoice3,
    GeneralSalesInvoices2,
    SalesInvoice2,
    SalesInvoice1,
    SalesRefund1,
    SalesCredit1,
    SalesDebit1,
    SalesQuotations1,

    GeneralSalesCredit1,
    GeneralSalesDebit1,
    GeneralSalesInvoices1,
    GeneralSalesRefund1,
    GeneralSalesRent1,
    SalesRent1,

    InvoicePdf,
  },
  props: {innerData: {}, dataHeader: {}, printType: null, showQRCode:{type: Boolean, default: true}, titleType:{type: String, default: 'normal'}},
  data() {
    return {
      mainRoute: 'settings/print_setting',
      template: [],
    }
  },

}
</script>

<style>
@import '~quill/dist/quill.core.css';
@media print {
  @page {
    size: a4;
    margin: 15px 0;
  }

}


div#invoice:not(.pdf) * {
  /*page-break-inside: avoid;*/
  /*page-break-before: auto;*/
}

.header-print {
  /*position: fixed;*/
  top: 20px;
  width: 754px;
}


.header-print-space {
  width: 754px;
  display: block;
  height: 150px;
}

.footer-print {
  /*position: fixed;*/
  bottom: 0;
  width: 754px;
  /*page-break-after:always;*/
}


div#invoice:not(.pdf) table.report-container {
  page-break-after: always;
}

table.report-container {
  text-align: unset !important;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

thead.no-report-header {
  display: contents;
  /*page-break-inside: avoid !important;*/
  /*page-break-after: avoid !important;*/
  /*page-break-before: avoid !important;*/
}
:not(.pdf) thead.no-report-header {
  display: contents;
  page-break-inside: avoid !important;
  page-break-after: avoid !important;
  page-break-before: avoid !important;
}

tfoot.no-report-footer {
  /*display: contents;*/
  display: block;
}
tfoot.no-report-footer tr{
  display: block;
}

table thead.report-header tr th, table thead.no-report-header tr th {
  border: 0 !important;
}

.main {
  width: 199mm;
}

.header-info {
  /*margin-bottom: 150px;*/
}

.report-header-cell {
  /*padding-bottom: 20px;*/
  font-weight: unset !important;
}

.break-inside-revert, .break-inside-revert * {
  break-inside: revert !important;
}

.page-break-inside-pdf {
  page-break-inside: avoid;
}

.invoice-box:not(.pdf) table thead.no-report-header,
.invoice-box:not(.pdf) tbody,
.invoice-box:not(.pdf) thead.no-report-header tr,
.invoice-box:not(.pdf) thead.no-report-header td,
.invoice-box:not(.pdf) tfoot.no-report-footer,
.invoice-box:not(.pdf) tfoot.no-report-footer tr,
.invoice-box:not(.pdf) tfoot.no-report-footer td{
  /*page-break-inside: auto !important;*/
  /*page-break-after: auto !important;*/
  /*page-break-before: auto !important;*/

  page-break-inside: unset !important;
  page-break-after: avoid !important;
  page-break-before: avoid !important;
}
.table tbody tr td{
  text-align: center !important;
}
.invoice-box:not(.pdf) tfoot.no-report-footer *{
  page-break-after: initial !important;
}
.title-1 {
  line-height: 1.2;
}
.title-2 {
  line-height: 1.2;
}
table[direction="ltr"] .table-border-radius{
  border-left: 0 !important;
}
table[direction="ltr"] .green-cc-3{
  text-align: left;
}
table[direction="ltr"] .par-h {
  margin-left: unset;
  margin-right: 10px;
}
table[direction="ltr"] .justify-content-between {
  flex-direction: row-reverse;
}
table[direction="ltr"] .no-revert, table[direction="ltr"] .page-break-inside-pdf {
  flex-direction: revert;
}
table[direction="ltr"] .total-border {
  flex-direction: unset;
}
.margin-left-025rem {
  margin-left: 0.25rem;
}
.margin-right-025rem {
  margin-right: 0.25rem;
}
table[direction="ltr"] .margin-left-025rem {
  margin-right: 0.25rem;
  margin-left: unset;
}
table[direction="ltr"] .margin-right-025rem {
  margin-left: 0.25rem;
  margin-right: unset;
}
table[direction="ltr"] table tbody tr td:last-child, table[direction="ltr"] table thead tr th:last-child {
  border-left: 2px solid #1a5a4a !important;
}
</style>