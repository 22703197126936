<template>
<!--  <div class="d-flex justify-content-between">-->
<!--    <div>-->
<!--      &lt;!&ndash; <template v-if="status == 'simplified'"> &ndash;&gt;-->
<!--      <template>-->
<!--        <p class="title-1 space-word-15">فاتورة مرتجع مبيعات عامة</p>-->
<!--        <p class="title-2">General Sales Refund Invoice</p>-->
<!--      </template>-->


<!--    </div>-->
<!--    <div class="d-flex">-->
<!--      <div>-->
<!--        <img v-if="rel_logo" :src="rel_logo" class="logo-w" alt="">-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <!-- <template v-if="status == 'simplified'"> -->
        <template v-if="innerData.is_apply_taxes_invoice">
          <p class="title-1 space-word-15" style="margin-bottom: 2px;line-height: 1.5;" v-if="business_name">{{business_name}}</p>
          <p class="title-1 space-word-15 title-1-size-20" style="margin-bottom: 2px;" v-if="business_name_en">{{business_name_en}}</p>
          <p class="title-2" style="margin-bottom: 2px;">
            <span> رقم الرخصة</span> <span>CR No. </span> <span>{{business_record}}</span>
            <span> / </span>
            <span> الرقم الضريبي</span> <span>VAT No: </span> <span>{{tax_no}}</span>
          </p>
        </template>
        <template v-else>
          <p class="title-1 space-word-15" style="margin-bottom: 2px;line-height: 1.5;" v-if="business_name">{{business_name}}</p>
          <p class="title-1 space-word-15 title-1-size-20" style="margin-bottom: 2px;" v-if="business_name_en">{{business_name_en}}</p>
          <p v-if="business_record" class="title-2" style="margin-bottom: 2px;">
            <span> رقم الرخصة</span> <span>CR No. </span> <span>{{business_record}}</span>
          </p>
        </template>

      </div>
      <div class="d-flex">
        <div>
          <img v-if="rel_logo" :src="rel_logo" class="logo-w" alt="">
        </div>

      </div>
      <img  class="watermark" v-if="rel_logo && show_watermark" :src="rel_logo" alt="">
    </div>
<!--    <template v-if="innerData.is_apply_taxes_invoice">-->
<!--      <h3 v-if="customer_type == 1" style="text-align: center;font-size: 20px;"><span>فاتورة ضريبية مبسطة</span> <span>SIMPLE TAX INVOICE</span></h3>-->
<!--      <h3 v-else style="text-align: center;font-size: 20px;"><span>فاتورة ضريبية</span> <span>TAX INVOICE</span></h3>-->
<!--    </template>-->
<!--    <template v-else>-->
<!--      <h3 style="text-align: center;font-size: 20px;"><span>فاتورة</span> <span>INVOICE</span></h3>-->
<!--    </template>-->
    <template>
      <h3 style="text-align: center;font-size: 20px;"><span>{{innerData ? innerData.invoice_title_ar: ''}}</span> <span>{{innerData ? innerData.invoice_title_en : '' }}</span></h3>
    </template>

  </div>
</template>

<script>
export default {
  name: "GeneralSalesRefund2",
  props: {innerData: {}, innerTemplate:{}},
  data(){
    return{
      rel_logo: null,
      business_name: null,
      business_name_en: null,
      tax_no: null,
      business_record: null,
      data:[],
      show_watermark: false,
      customer_type: null,
    }
  },
  created() {
    if(this.innerTemplate){
      this.show_watermark = this.innerTemplate.is_use_watermark;
    }
    if (this.innerData && this.innerData.company){
      this.business_name = this.innerData.company.business_name ? this.innerData.company.business_name : null;
      this.business_name_en = this.innerData.company.business_name_en ? this.innerData.company.business_name_en : null;
      this.tax_no = this.innerData.company.tax_no ? this.innerData.company.tax_no : null;
      this.business_record = this.innerData.company.business_record ? this.innerData.company.business_record : null;
    }
    if (this.innerData && this.innerData.company && this.innerData.company.logo_url) {
      // this.getBase64Image(this.innerData.company.logo_url).then((res) => {
      this.rel_logo = this.innerData.company.logo_url;
      // })
    }else{
      // this.rel_logo = "https://upload.wikimedia.org/wikipedia/commons/e/eb/Creeperasha_Linux_test_image_upload.png";
      this.rel_logo = null;
    }
    if (this.innerData && this.innerData.customer) {
      this.customer_type = this.innerData.customer.customer_type;
    }

  }
}
</script>

<style scoped>
.title-1 {
  font-size: 30px;
  color: #1a5a4a;
}
.title-1-size-20 {
  font-size: 20px;
}
.logo-w {
  width: 100px;
  margin-left: 0;
}
.title-2 {
  font-size: 20px;
  color: #000;
  padding-top: 8px;
}
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: 0;
  color: white;
  width: 579px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>