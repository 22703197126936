<template>
  <div class="d-flex justify-content-between">
<!--    <div v-if="innerData.status && innerData.status == 1">-->
<!--      <p class="title-1 space-word-15" style="line-height: 1.5;">فاتورة مبدئية</p>-->
<!--      <p class="title-2">Proforma Invoice</p>-->
<!--    </div>-->
<!--    <div v-else-if="innerData.is_apply_taxes_invoice">-->
<!--      <template v-if="customer_type == 1">-->
<!--        <p class="title-1 space-word-15">فاتورة ضريبية مبسطة</p>-->
<!--        <p class="title-2">Simple Tax Invoice</p>-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <p class="title-1 space-word-15">فاتورة ضريبية</p>-->
<!--        <p class="title-2">Tax Invoice</p>-->
<!--      </template>-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <p class="title-1 space-word-15">فاتورة</p>-->
<!--      <p class="title-2">Invoice</p>-->
<!--    </div>-->
    <div>
      <p class="title-1 space-word-15">{{innerData ? innerData.invoice_title_ar: ''}}</p>
      <p class="title-2">{{innerData ? innerData.invoice_title_en : '' }}</p>
    </div>
    <div class="d-flex">
      <div>
        <img id="rel_logo" v-if="rel_logo" :src="rel_logo" class="logo-w" alt="">
      </div>

    </div>

    <img  class="watermark" v-if="rel_logo && show_watermark" :src="rel_logo" alt="">
  </div>

</template>

<script>
export default {
  name: "GeneralSalesInvoices1",
  props: {innerData: {}, innerTemplate:{}},
  data() {
    return {
      rel_logo: null,
      data: [],
      show_watermark: false,
      customer_type: null,
    }
  },
  methods: {

  },
  created() {
    if(this.innerTemplate){
      this.show_watermark = this.innerTemplate.is_use_watermark;
    }
    if (this.innerData && this.innerData.company && this.innerData.company.logo_url) {
      // this.getBase64Image(this.innerData.company.logo_url).then((res) => {
        this.rel_logo = this.innerData.company.logo_url;
      // })
    }else{
      // this.rel_logo = "https://upload.wikimedia.org/wikipedia/commons/e/eb/Creeperasha_Linux_test_image_upload.png";
      this.rel_logo = null;
    }
    if (this.innerData && this.innerData.customer) {
      this.customer_type = this.innerData.customer.customer_type;
    }

  }
}
</script>

<style scoped>
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: 0;
  color: white;
  width: 579px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>